.rightUp {
  /* transform: matrix(1, -0.75, 0, 1, 0, 15); */
  transform: matrix(1.08, -0.28, 0, 1, 0, 9);
  /* 
        0.75 = 30 / 40 (height / width)
        15 = 30 / 2
    */ }

.diagonal {
  height: 100%;
  width: 100%;
  border-top: solid gray 1px; }

td line {
  stroke: #999;
  stroke-width: 1px; }

.report-input table td,
.report-input .border-right,
.report-input .border-left,
.report-input .border-top,
.report-input .border-bottom {
  border-color: #666 !important; }
